import * as Sentry from "@sentry/remix";
import mixpanel from "mixpanel-browser";

import { type TrackingEventArgs } from "./events/index.ts";

import { type User } from "#src/repos/deprecated/user/index.ts";
import { isomorphicEnv } from "#src/utils/isomorphicEnv.ts";

type IdentifyUserArgs = {
  features: string[];
  user: Pick<User, "email" | "id" | "roles">;
};

export function identifyUser({ features, user }: IdentifyUserArgs) {
  if (!isomorphicEnv.MIXPANEL_ENABLED) {
    // eslint-disable-next-line no-console
    console.info("Mixpanel is disabled, skipping identify user");
    return;
  }

  try {
    // eslint-disable-next-line import/no-named-as-default-member
    mixpanel.identify(user.id.toString());

    // Set these properties on the user's profile
    // eslint-disable-next-line import/no-named-as-default-member
    mixpanel.people.set({
      email: user.email,
      features: features,
      roles: user.roles,
      userId: user.id,
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.info("Failed to identify user for Mixpanel");
    Sentry.captureException(e);
  }
}

export function trackEvent(event: TrackingEventArgs) {
  if (!isomorphicEnv.MIXPANEL_ENABLED) {
    // eslint-disable-next-line no-console
    console.info("Mixpanel is disabled, skipping track event", { event });
    return;
  }

  try {
    const { type, ...properties } = event;
    // eslint-disable-next-line import/no-named-as-default-member
    mixpanel.track(type, properties);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.info("Failed to send event to Mixpanel");
    Sentry.captureException(e);
  }
}
